import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { ClerkProvider } from '@clerk/clerk-react';
import { neobrutalism } from '@clerk/themes';

const rootElement = document.getElementById("root");

// Use JSDoc to indicate the type
/** @type {HTMLDivElement} */
if (!rootElement) throw new Error("Root element not found");
const root = ReactDOM.createRoot(rootElement);

//pk_test_bG95YWwtZ29yaWxsYS02Ny5jbGVyay5hY2NvdW50cy5kZXYk
//pk_live_Y2xlcmsubmVvY3UuY29tJA

// JSDoc for environment variable
/** @type {string} */
const publishableKey = 'pk_live_Y2xlcmsubmVvY3UuY29tJA';
if (!publishableKey) {
    throw new Error("Missing _CLERK_PUBLISHABLE_KEY env variable");
}



root.render(
  <React.StrictMode>
 <ClerkProvider
  publishableKey={publishableKey}
 
  appearance={{
    baseTheme: 'dark',
    variables: {
      colorPrimary: '#FF6700',
      colorBackground: '#000000',
      colorText: '#FFFFFF',
      colorTextOnPrimaryBackground: '#FFFFFF',
      colorTextSecondary: '#FFFFFF',
      colorInputText: '#FFFFFF',
      spacingUnit: '0.6rem',
      marginTop:'2vh'
    },
    elements: {
      card: {
        fontSize: '0.5rem',
        backgroundColor: '#000000',
        maxHeight: '95vh',
       
      
      },
    },
  }}
>
  <App />
</ClerkProvider>
  </React.StrictMode>
);