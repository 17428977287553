import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useParams, useNavigate, useLocation, Link } from 'react-router-dom';
import { useSwipeable } from 'react-swipeable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWandSparkles, faDollarSign, faGamepad, faPlay, faUserCircle, faXmark, faBars } from '@fortawesome/free-solid-svg-icons';
import './Feed.css';
import logo from './../logo.png';
import { SignInButton, SignUpButton, SignOutButton, useUser, UserProfile } from "@clerk/clerk-react";

const API_URL = 'https://api.neocu.com/api/feed/games?category=sku';
const ADVISOR_API_URL = 'https://api.neocu.com/api/advisor';
const IP_LOOKUP_URL = 'https://api.ipify.org?format=json';

function Feed() {
  const [feedItems, setFeedItems] = useState([]);
  const [currentItemIndex, setCurrentItemIndex] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isPaginating, setIsPaginating] = useState(false);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [initialIdLoad, setInitialIdLoad] = useState(true);
  const [notification, setNotification] = useState({ visible: false, message: '' });
  const [slideDirection, setSlideDirection] = useState('');
  const [userIp, setUserIp] = useState(null);
  const [showUserDropdown, setShowUserDropdown] = useState(false);
  const [isUserProfileModalOpen, setIsUserProfileModalOpen] = useState(false);
  const [isAuthModalOpen, setIsAuthModalOpen] = useState(false); // New state for auth modal
  const dropdownRef = useRef(null);
  const { isSignedIn, user } = useUser();

  const { id: routeId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const isMounted = useRef(true);
  const notificationTimeout = useRef(null);

    // Close dropdown on click outside
    useEffect(() => {
        function handleClickOutside(event) {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setShowUserDropdown(false);
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [dropdownRef]);

    const closeUserDropdown = () => {
        setShowUserDropdown(false);
    };

    const toggleUserDropdown = () => {
        setShowUserDropdown(prev => !prev);
    };

    const handleProfileClick = () => {
        setIsUserProfileModalOpen(true);
        closeUserDropdown();
    };

    const closeUserProfileModal = () => {
        setIsUserProfileModalOpen(false);
    };

    const closeAuthModal = () => {
        setIsAuthModalOpen(false);
        setIsPlaying(false); // Optionally stop playing when auth modal closes
    };


  // --- Fetch User IP ---
  useEffect(() => {
    const fetchUserIp = async () => {
      try {
        const response = await fetch(IP_LOOKUP_URL);
        if (response.ok) {
          const data = await response.json();
          setUserIp(data.ip);
        } else {
          console.error("Failed to fetch user IP address.");
        }
      } catch (error) {
        console.error("Error fetching user IP:", error);
      }
    };
    fetchUserIp();
  }, []);

  // --- Tracking Function ---
  const trackAction = useCallback(async (actionType, feedId) => {
    if (!userIp || !feedId) {
      console.warn(`User IP or Feed ID missing, cannot track action ${actionType}`);
      return;
    }
    const payload = {
      action: actionType,
      sku_id: feedId,
      ip: userIp,
    };

    try {
      const response = await fetch(ADVISOR_API_URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
        keepalive: true, // Use keepalive to ensure non-blocking
      });
      if (!response.ok) {
        console.error(`Advisor API request failed for action ${actionType} with status: ${response.status}`);
      }
    } catch (error) {
      console.error(`Error tracking action ${actionType}:`, error);
    }
  }, [userIp]);


  // --- Data Fetching ---
  const fetchFeed = useCallback(async (page, initialLoadItemId = null) => {
    if (page > totalPages && !initialLoadItemId && page !== 1) {
        console.log("Already fetched all pages or invalid page number.");
        return false;
    }
    console.log(`Fetching feed page ${page}...`);
    if (page > 1) setIsPaginating(true);
    else setIsLoading(true);
    setError(null);

    try {
      const response = await fetch(`${API_URL}&page=${page}`);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();

      if (isMounted.current) {
          if (data.isSuccess && data.feedList) {
            const newItems = data.feedList;
            let foundIndex = -1;

            if(initialLoadItemId) {
                foundIndex = newItems.findIndex(item => item._id === initialLoadItemId);
                if (foundIndex === -1 && page < data.totalPages) {
                    fetchFeed(page + 1, initialLoadItemId);
                    return true;
                } else if (foundIndex === -1) {
                    console.error(`Item with ID ${initialLoadItemId} not found in feed.`);
                    setError(`Content with ID ${initialLoadItemId} not found.`);
                    setFeedItems(newItems); // Load page 1 as fallback
                    setCurrentItemIndex(0);
                    setTotalPages(data.totalPages);
                    setCurrentPage(1);
                     if (newItems.length > 0) {
                         navigate(`/${newItems[0]._id}`, { replace: true });
                         trackAction('impression', newItems[0]._id); // Track impression for fallback item
                    }
                }
            }

            setFeedItems(prevItems => (page === 1 ? newItems : [...prevItems, ...newItems]));
            setTotalPages(data.totalPages);
            setCurrentPage(page);

            if (foundIndex !== -1) {
                setCurrentItemIndex(foundIndex);
            } else if (page === 1 && !initialLoadItemId && newItems.length > 0) {
                setCurrentItemIndex(0);
                if (location.pathname !== `/feed/${newItems[0]._id}`) {
                    navigate(`/${newItems[0]._id}`, { replace: true });
                }
            }

             // Track impression after setting feed items and index, for initial load or page change
            if (newItems.length > 0 && (foundIndex !== -1 || page === 1)) {
                const impressionIndex = foundIndex !== -1 ? foundIndex : 0;
                trackAction('impression', newItems[impressionIndex]._id);
            }

          } else {
            throw new Error(data.message || 'Failed to fetch feed data.');
          }
      }

    } catch (err) {
       if (isMounted.current) {
           console.error("Fetch error:", err);
           setError('System Overload'); // Set specific error message for System Overload
       }
    } finally {
       if (isMounted.current) {
           setIsLoading(false);
           setIsPaginating(false);
       }
    }
    return true;
  }, [navigate, totalPages, location.pathname, trackAction]);


  // --- Effect for Initial Load ---
  useEffect(() => {
    isMounted.current = true;
    if (routeId && initialIdLoad) {
      console.log("Attempting initial load for ID:", routeId);
      fetchFeed(1, routeId);
      setInitialIdLoad(false);
    } else if (!routeId && initialIdLoad) {
      console.log("No ID in route, fetching page 1");
      fetchFeed(1);
      setInitialIdLoad(false);
    }
    return () => {
      isMounted.current = false;
      clearTimeout(notificationTimeout.current);
      console.log("Feed component unmounted");
    };
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [routeId, fetchFeed]);


  // --- Navigation ---
   const navigateItem = (newIndex, direction) => {
    if (newIndex >= 0 && newIndex < feedItems.length) {
      setSlideDirection(direction);
      setCurrentItemIndex(newIndex);
      setIsPlaying(false); // Stop playing when navigating
      const newItemId = feedItems[newIndex]._id;
      if (location.pathname !== `/feed/${newItemId}`) {
        navigate(`/${newItemId}`, { replace: true });
      }
       setTimeout(() => {
           if (isMounted.current) setSlideDirection('');
       }, 500);
        trackAction('impression', newItemId); // Track impression when navigating to a new item

    } else if (newIndex >= feedItems.length && currentPage < totalPages && !isPaginating) {
      fetchFeed(currentPage + 1).then((success) => {
        if (success && isMounted.current) {
          setSlideDirection(direction);
          setTimeout(() => {
             if(isMounted.current && newIndex < feedItems.length) {
                 setCurrentItemIndex(newIndex);
                 setIsPlaying(false); // Ensure stopped if new item loaded
                 navigate(`/${feedItems[newIndex]._id}`, { replace: true });
                 setTimeout(() => {
                    if (isMounted.current) setSlideDirection('');
                 }, 500);
                 trackAction('impression', feedItems[newIndex]._id); // Track impression when paginating to a new item
             } else if (isMounted.current) {
                 setSlideDirection('');
             }
          }, 100);
        } else {
             if (isMounted.current) setSlideDirection('');
        }
      });
    } else {
        setSlideDirection(direction);
         setTimeout(() => {
            if (isMounted.current) setSlideDirection('');
         }, 100);
    }
  };

  const handleNext = () => {
    navigateItem(currentItemIndex + 1, 'up');
  };

  const handlePrevious = () => {
    navigateItem(currentItemIndex - 1, 'down');
  };

  // --- Swipe Handling ---
  const swipeHandlers = useSwipeable({
    onSwipedUp: () => handleNext(),
    onSwipedDown: () => handlePrevious(),
    preventScrollOnSwipe: true,
    trackMouse: true
  });

  // --- Actions ---
  const handlePlayOrRequest = () => {
    if (!isSignedIn) {
      setIsAuthModalOpen(true); // Open auth modal if not signed in
      return; // Prevent further action
    }

    if (currentItem?.type === 'embed') {
      setIsPlaying(true);
      trackAction('play', currentItem._id); // Track play action
    }
    if (currentItem?.type === 'video') {
      showNotification(`Early access requested for "${currentItem.title}"!`);
      trackAction('request_early_access', currentItem._id); // Track request early access
    }
  };

  const handleLeave = () => {
    setIsPlaying(false);
    if(currentItem) {
      trackAction('leave', currentItem._id); // Track leave action
    }
  };

  // --- Notification Helper ---
  const showNotification = (message) => {
      clearTimeout(notificationTimeout.current);
      setNotification({ visible: true, message });
      notificationTimeout.current = setTimeout(() => {
          if (isMounted.current) {
             setNotification({ visible: false, message: '' });
          }
      }, 2500);
  };

  // --- Share Action ---
  const handleShare = async () => {
    const currentUrl = `${window.location.origin}/${currentItem?._id}`;
    try {
      await navigator.clipboard.writeText(currentUrl);
      showNotification("Link copied to clipboard!");
      trackAction('share', currentItem._id); // Track share action
    } catch (err) {
      console.error('Failed to copy URL: ', err);
      showNotification("Failed to copy link.");
    }
  };

  // --- Rendering ---
  const currentItem = feedItems.length > 0 ? feedItems[currentItemIndex] : null;

  if (isLoading && !isPaginating) {
    return (
      <div className="feed-container loading-indicator">
        <div className="orange-spinner"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="feed-container error-container">
        <div className="error-card">
          <div className="error-title">System Overload</div>
          <div className="error-description">We're dealing with increasing demand. Please come back in a few minutes.</div>
        </div>
      </div>
    );
  }

  if (!currentItem) {
    return <div className="feed-container">No feed items available.</div>;
  }

  const isEmbed = currentItem.type === 'embed';
  const isVideo = currentItem.type === 'video';
  const thumbnailUrl = window.innerWidth <= 768 && currentItem.mobile_thumbnail !== 'none'
                       ? currentItem.mobile_thumbnail
                       : currentItem.desktop_thumbnail;
  const showPlaceholder = thumbnailUrl === 'none' || (!isEmbed && !isVideo);

  const contentKey = `${currentItem._id}-${isPlaying}`;
  const animationClass = slideDirection === 'up' ? 'animate-slide-up' : (slideDirection === 'down' ? 'animate-slide-down' : '');


  return (
    <>
    <div
      {...swipeHandlers}
      className={`feed-container ${isPlaying ? 'is-playing' : ''}`}
      data-single-item={feedItems.length <= 1}
    >
      {/* Logo, Top Icons, Loading, Leave Button controlled by CSS transitions/visibility */}
      <img src={logo} alt="Logo" className="feed-logo" />

      <div className="top-right-icons">
        {isSignedIn && (
            <>
                 <Link to="/imagine" title="Imagine">
                     <FontAwesomeIcon icon={faWandSparkles} />
                 </Link>
                 <Link to="/earn" title="Earn">
                     <FontAwesomeIcon icon={faDollarSign} />
                 </Link>
                  <Link to="/games" title="Games">
                     <FontAwesomeIcon icon={faGamepad} />
                 </Link>
            </>
        )}


        {/* Profile Section */}
        <div className={`profile-container-header`} ref={dropdownRef}>
            {!isSignedIn ? (
                <SignInButton mode="modal" asChild afterSignInUrl="/">
                    <button className="signin-button-custom">
                        Login
                    </button>
                </SignInButton>
            ) : (
                <>
                    <button className="profile-button-header" onClick={toggleUserDropdown}>
                        {user?.imageUrl ? (
                             <img src={user.imageUrl} alt="Profile" className="desktop-profile-pic" />
                        ) : user?.firstName ? (
                            <span className="profile-initials-header">
                                {user.firstName.charAt(0).toUpperCase()}
                            </span>
                        ) : (
                            <FontAwesomeIcon icon={faUserCircle} style={{ color: '#90D6FF', fontSize: '1.7em'}} />
                        )}
                    </button>

                    {showUserDropdown && (
                        <div className="user-dropdown-header">
                            <div className="dropdown-item" onClick={handleProfileClick}>
                                Profile
                            </div>
                            <div className="dropdown-item signout-dropdown-item">
                                <SignOutButton>
                                    Sign Out
                                </SignOutButton>
                            </div>
                        </div>
                    )}
                </>
            )}
        </div>
      </div>

      {isPaginating && <div className="loading-overlay">Loading more...</div>}

      <button onClick={handleLeave} className="feed-leave-button">
        Leave
      </button>

      {/* Content */}
      <div key={contentKey} className={`feed-content-wrapper ${animationClass}`}>
        {isEmbed && !isPlaying && (
            showPlaceholder
            ? <div className="feed-placeholder">Preview N/A</div>
            : <img
                src={thumbnailUrl}
                alt={`${currentItem.title} thumbnail`}
                className="feed-thumbnail"
                onError={(e) => { e.target.style.display = 'none'; }}
             />
        )}
        {isEmbed && isPlaying && (
          <iframe
            src={currentItem.name}
            title={currentItem.title}
            className="feed-iframe"
            allow="accelerometer; ambient-light-sensor; camera; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>
        )}
        {isVideo && (
          <video
            src={currentItem.name}
            className="feed-video"
            autoPlay
            loop
            muted
            playsInline
            controls={false}
          ></video>
        )}
         {!isEmbed && !isVideo && showPlaceholder && (
             <div className="feed-placeholder">Content N/A</div>
         )}
      </div>

      {/* Info Box */}
      <div className="feed-info">
        <div className="feed-title">{currentItem.title}</div>
        <div className="feed-description">{currentItem.description}</div>
        <div className="feed-username">
          by{' '}
          {currentItem.username_link && currentItem.username_link !== 'none' ? (
            <a href={currentItem.username_link} target="_blank" rel="noopener noreferrer">
              {currentItem.username}
            </a>
          ) : (
            currentItem.username
          )}
        </div>

        {isVideo && (
            <button onClick={handlePlayOrRequest} className="action-text-request">
                Request Early Access
            </button>
        )}
        {isEmbed && (
            <button onClick={handlePlayOrRequest} className="action-icon-play" title="Play">
                <FontAwesomeIcon icon={faPlay} />
            </button>
        )}
      </div>


      {/* Nav Arrows */}
      {currentItemIndex > 0 && (
        <div className="feed-nav-arrow arrow-up" onClick={handlePrevious} title="Previous">
          ▲
        </div>
      )}
      {(currentItemIndex < feedItems.length - 1 || currentPage < totalPages) && (
         <div className="feed-nav-arrow arrow-down" onClick={handleNext} title="Next">
           ▼
         </div>
      )}

      {/* Share Button */}
      <div className="feed-share-button" onClick={handleShare} title="Copy Link">
        {/* Icon handled by CSS ::before */}
      </div>

      {/* Notification */}
      <div className={`copy-notification ${notification.visible ? 'visible' : ''}`}>
          {notification.message}
      </div>

    </div>
     {/* User Profile Modal */}
     {isUserProfileModalOpen && (
        <UserProfileModal onClose={closeUserProfileModal} />
    )}
    {/* Auth Modal */}
    {isAuthModalOpen && (
        <AuthModal onClose={closeAuthModal} />
    )}
    </>
  );
}

// User Profile Modal Component (Unchanged)
const UserProfileModal = ({ onClose }) => {
    return (
        <div className="compute-modal-overlay" onClick={onClose} style={{ backgroundColor: 'rgba(0, 0, 0, 0.9)' }}>
            <div className="compute-modal-content" onClick={(e) => e.stopPropagation()}>
                <button className="modal-close-button" onClick={onClose}>
                    <FontAwesomeIcon icon={faXmark} />
                </button>
                 {/* Added padding inside modal content for Clerk component */}
                <div style={{ padding: '20px', width: '100%' }}>
                     <UserProfile path="/user-profile" routing="path" /> {/* Use path routing */}
                </div>
            </div>
        </div>
    );
};

// Auth Modal Component
const AuthModal = ({ onClose }) => {
    return (
        <div className="auth-modal-overlay">
            <div className="auth-modal-content">
                <button className="auth-modal-close-button" onClick={onClose}> {/* Close button added */}
                    <FontAwesomeIcon icon={faXmark} />
                </button>
                <h2 className="auth-modal-title">Neocu Community</h2>
                <ul className="auth-modal-checklist">
                    <li>Discover exclusive game content.</li>
                    <li>Get early access to new experiences.</li>
                    <li>Connect with a community of gamers.</li>
                    <li>Personalize your feed with your interests.</li>
                    <li>Support creators and the gaming ecosystem.</li>
                </ul>
                <div className="auth-modal-buttons">
                    <SignInButton mode="modal" asChild afterSignInUrl="/">
                        <button className="auth-modal-button auth-modal-login-button">Login</button>
                    </SignInButton>
                    <SignUpButton mode="modal" asChild afterSignUpUrl="/">
                        <button className="auth-modal-button auth-modal-signup-button">Sign Up</button>
                    </SignUpButton>
                </div>
            </div>
        </div>
    );
};


export default Feed;